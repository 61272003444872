import React from "react";
import Marquee from "react-fast-marquee";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  Container,
  HeroContainer,
  Heading,
  SpaceSmall,
  SpaceMedium,
  SpaceLarge,
  Paragraph,
  CustomButton,
  ButtonSpace,
  PreHeading,
  CardParagraph,
  Span,
} from "../../styles/StyledComponents";

import {
  HomeImages,
  PartnerBrands1,
  PartnerBrands2,
  bespokeSupport,
  ourBootcamps,
} from "../../constants";
import { Modal, Button } from "antd";
import { AiOutlineExport } from "react-icons/ai";
import { HomeContainer } from "./style.js";
import OurGrads from "../../components/Grads";
import { useState } from "react";
import Eligibility from "../../components/Eligibility";
import TextAnimation from "react-text-animations";
import BannerImg from "../../assets/niyo-banner.png";
import BannerImgMobile from "../../assets/niyo-banner-mobile.png";
import "./banner.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showBanner, SetShowBanner] = useState(true);

  const showModal = () => {
    setOpen(true);
  };

  const handleOK = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleBanner = () => {
    SetShowBanner(true);
  };

  const closeBanner = () => {
    SetShowBanner(false);
  };
  return (
    <>
      <HomeContainer>
        <HeroContainer height={"auto"} className="hero-container">
          <Container className="hero-left">
            <Heading fontSize={"56px"} className="hero-heading" color="#000">
              <TextAnimation.Slide
                target="tech"
                text={["tech", "fashion", "Hair", "Beauty"]}
                animation={{
                  duration: 1000,
                  delay: 1500,
                  timingFunction: "ease-in-out",
                }}
              >
                #1 Training Ground for ambitious people in tech globally.
              </TextAnimation.Slide>
            </Heading>
            <Paragraph className="hero-paragraph">
              We upskill, we support, we get them employed! - Feel empowered, be
              empowered.
            </Paragraph>
            <ButtonSpace />
            <div className="button-container">
              <Link to="/bootcamps">
                {" "}
                <CustomButton className="button-1">View bootcamps</CustomButton>
              </Link>

              <Modal
                open={open}
                onOk={handleOK}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                className="eligibility-modal"
                width={success ? "40%" : "90%"}
                bodyStyle={success ? { height: "" } : { height: "auto" }}
                style={success ? { top: 200 } : { top: 10 }}
                footer={null}
              >
                <Eligibility success={success} setSuccess={setSuccess} />
              </Modal>
            </div>
            <SpaceLarge className="mobile-space" />
          </Container>

          <Container className="hero-right">
            <div className="image-col-1">
              <div>
                <Link to="/data-course/:id">
                  <div className="hero-image image-left image-1">
                    <div className="hero-tag tag-1">Data Analytics</div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/software-course/:id">
                  <div className="hero-image image-right image-3">
                    <div className="hero-tag tag-3">Software Development</div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="image-col-2">
              <div>
                <Link to="/foundhers-course/:id">
                  <div className="hero-image image-right image-2">
                    <div className="hero-tag tag-2">FoundHers</div>
                  </div>
                </Link>
              </div>

              <div>
                <Link to="/vr-coursee/:id">
                  <div className="hero-image image-left image-4">
                    <div className="hero-tag tag-4">VR / AR</div>
                  </div>
                </Link>
              </div>
            </div>
            <div></div>
          </Container>
        </HeroContainer>

        <Container className="mission-section">
          <SpaceMedium className="mobile-space" />
          <PreHeading>OUR MISSION</PreHeading>
          <Heading className="mission-heading">
            To upskill <span>1 million </span>people <br /> and get 90% of them{" "}
            <br /> in a job or self employment.
          </Heading>
          <ButtonSpace />
          <Link to="/bootcamps">
            {" "}
            <CustomButton>
              <div className="button-arrow">
                View Bootcamps
                <img
                  src={HomeImages.whiteArrow}
                  className="white-arrow"
                  alt="arrow"
                />
              </div>
            </CustomButton>
          </Link>
          <SpaceMedium className="mobile-space  desktop-space" />
        </Container>

        <Container className="partners-section">
          <Heading>Our Partners</Heading>
          <Paragraph className="partners-text">
            Since 2020, We have partnered with these companies to <br />
            upskill and enable 560 people to break into tech.
          </Paragraph>
          <SpaceSmall />
          <div className="partners-video">
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/YJ5vANr_sl8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              className="video"
            ></iframe>
          </div>

          <Container className="logo-container">
            <Marquee
              speed="30"
              gradientWidth="0"
              pauseOnHover="true"
              className="marquee-wrap"
            >
              {PartnerBrands1.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item.image}
                      className="logo-individual"
                      alt="logo"
                    />
                  </div>
                );
              })}
            </Marquee>

            <Marquee
              direction="right"
              speed="30"
              gradientWidth="0"
              pauseOnHover="true"
              className="marquee-wrap"
            >
              {PartnerBrands2.map((item, index) => {
                return (
                  <div key={index}>
                    <img
                      src={item.image}
                      className="logo-individual"
                      alt="logo"
                    />
                  </div>
                );
              })}
            </Marquee>
          </Container>
          <SpaceSmall />

          <Link to="/partners">
            {" "}
            <CustomButton className="button-container">
              <div className="partner-button">
                <div className="button-arrow">
                  Partner with us{" "}
                  <img
                    src={HomeImages.whiteArrow}
                    className="white-arrow"
                    alt="arrow"
                  />
                </div>
              </div>
            </CustomButton>{" "}
          </Link>
          <SpaceMedium />
        </Container>

        {/* <Container className="start-career">
        <PreHeading>START YOUR CAREER WITH US</PreHeading>
        <SpaceSmall />

        <div className="career-text-blocks">
          <Container className="career-left">
            <Heading>
              Change your life, <br /> Start <span>with Tech.</span>
            </Heading>
          </Container>
          <Container className="career-right">
            <Paragraph className="career-paragraph">
              During our bootcamps, learn the most in-
              <br />
              demand skills with other ambitious black <br /> women, passionate
              teachers, and a hands-
              <br />
              on curriculum teaching you how to build <br /> technology
              solutions and solve problems.
            </Paragraph>
            <ButtonSpace />
            <a
              href="https://www.canva.com/design/DAE4n1iLww8/8aVTroxBOPZ0uGSC_sc8-Q/edit"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <CustomButton width={"320px"} className={"career-button"}>
                <div className="button-arrow ">
                  Download our 2021 Impact report
                  <img
                    src={HomeImages.whiteArrow}
                    className="white-arrow"
                    alt="arrow"
                  />
                </div>
              </CustomButton>
            </a>
          </Container>
        </div>
        <SpaceMedium />
      </Container> */}

        <Container className="bootcamps-section">
          <PreHeading>OUR BOOTCAMPS</PreHeading>
          <SpaceSmall />
          <Heading>
            Find the right <br />
            <span>Bootcamp</span> for you.
          </Heading>
          <SpaceSmall />

          <Container className="bootcamp-card-section">
            <Carousel
              swipeable={true}
              responsive={responsive}
              className="bootcamps-carousel"
            >
              {ourBootcamps.map((item, index) => {
                return (
                  <div
                    className="bootcamp-card"
                    key={index}
                    style={{ backgroundColor: item.textBackground }}
                  >
                    <img
                      src={item.image}
                      className="bootcamp-image"
                      alt="bootcamps"
                    />

                    <div className="bootcamp-text">
                      <Heading
                        fontSize={"16px"}
                        lineHeight={"18px"}
                        className="bootcamp-title"
                        color={item.color}
                      >
                        {item.name}
                      </Heading>
                      <CardParagraph
                        color={item.color}
                        style={{ marginTop: "15px" }}
                      >
                        {item.description}
                      </CardParagraph>
                      <SpaceSmall />
                      <Link to={item.buttonLink}>
                        <CustomButton
                          className="button-arrow bootcamp-button"
                          style={{
                            backgroundColor: item.textBackground,
                            color: item.button,
                            borderColor: item.button,
                          }}
                        >
                          View bootcamps
                          <img
                            src={item.arrow}
                            className="white-arrow"
                            alt="arrow"
                          />
                        </CustomButton>
                      </Link>
                      <SpaceSmall />
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Container>

          <Container className="bootcamps-list-container">
            <div>
              {ourBootcamps.map((item, index) => {
                return (
                  <div
                    className="bootcamp-card"
                    key={index}
                    style={{ backgroundColor: item.textBackground }}
                  >
                    <img
                      src={item.image}
                      className="bootcamp-image"
                      alt="bootcamps"
                    />

                    <div className="bootcamp-text">
                      <Heading
                        fontSize={"16px"}
                        lineHeight={"22px"}
                        className="bootcamp-title"
                        color={item.color}
                      >
                        {item.name}
                      </Heading>
                      <SpaceSmall />
                      <CardParagraph color={item.color}>
                        {item.description}
                      </CardParagraph>
                      <SpaceSmall />
                      <CardParagraph
                        color={item.color}
                        className="bootcamp-ready"
                      >
                        {item.ready}
                      </CardParagraph>

                      <ButtonSpace className="bootcamp-ready" />
                      <CustomButton
                        className="button-arrow bootcamp-button"
                        style={{
                          backgroundColor: item.textBackground,
                          color: item.button,
                          borderColor: item.button,
                        }}
                      >
                        View Bootcamps
                        <img
                          src={item.arrow}
                          className="white-arrow"
                          alt="arrow"
                        />
                      </CustomButton>
                      <SpaceSmall />
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>

          <SpaceMedium className="mobile-space" />
        </Container>

        <OurGrads />

        <div className="image-container"></div>

        <Container className="why-niyo">
          <SpaceMedium />
          <PreHeading>WHY NIYO BOOTCAMP</PreHeading>
          <SpaceSmall />
          <Heading className="bespoke-title">
            Bespoke all round <br />
            <span>support!</span>
          </Heading>
          <SpaceSmall className="hidden-space" />
          <Container className="support-blocks">
            {bespokeSupport.map((item, index) => {
              return (
                <div className="support-card" key={index}>
                  <img src={item.logo} className="support-logo" alt="logo" />
                  <Heading
                    fontSize={"24px"}
                    lineHeight={"30px"}
                    className="card-heading"
                  >
                    <a href={item.hyperLink}>{item.title}</a>
                  </Heading>
                  <CardParagraph className="card-text">
                    {item.text}
                  </CardParagraph>
                </div>
              );
            })}
          </Container>
        </Container>
        <Modal
          open={showBanner}
          onOk={closeBanner}
          confirmLoading={confirmLoading}
          onCancel={closeBanner}
          className=" bg-black"
          width={1100}
          centered
          footer={null}
        >
          <div className="banner-image">
            <img src={BannerImg} alt="banner" />
          </div>
          <div className="banner-image-mobile">
            <img src={BannerImgMobile} alt="banner" />
          </div>
          <div className="banner-text">
            <a href="https://niyofest.com/tickets/" target="_blank">
              <CustomButton>
                <div className="button-arrow">
                  Get Tickets{" "}
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.967 17.4655L17.5564 12.3768M12.967 7.28811L17.5564 12.3768M17.5564 12.3768L4.44358 12.3768"
                      stroke="white"
                      stroke-width="1.69841"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </CustomButton>
            </a>
          </div>
        </Modal>
      </HomeContainer>
    </>
  );
};

export default Home;
